<template>
  <template v-if="deviceType === 'desktop'">
    <div class="row">
      <div class="col-12 mt-5">
        <div class="card fm-desktop-card">
          <div class="card-body text-center">
            <h5 class="card-title fm-label-main-color fm-desktop-card-title">{{title}}</h5>
            <p class="card-title fm-label-main-color fm-desktop-card-form-label">{{message1}}</p>
            <p class="card-title fm-label-main-color fm-desktop-card-form-label">{{message2}}</p>
            <img class="img-fluid fm-image" v-bind:src="image">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 fm-main-label fm-label-main-color text-center">{{title}}</div>
      <div class="col-md-3"></div>
    </div>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 fm-info">
        {{message1}}
      </div>
      <div class="col-md-3"></div>
    </div>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 fm-info">
        {{message2}}
      </div>
      <div class="col-md-3"></div>
    </div>

    <div class="row fm-image-margin">
      <div class="col-md-3"></div>
      <div class="col-md-6">
        <img v-bind:src="image">
      </div>
      <div class="col-md-3"></div>
    </div>
    <slot></slot>
  </template>
</template>

<script>
export default {
  name: 'InfoPage',
  props: {
    title: String,
    message1: String,
    message2: String,
    image: String
  },
  data() {
    return {
      deviceType: 'desktop'
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    }
  }
};
</script>

<style scoped>
.fm-info {
  color: var(--auro-metal-saurus);
}

.fm-image-margin {
  margin-top: 2.25rem;
}

.fm-image {
  height: 25vh;
}

.fm-desktop-card {
  border-radius: 2rem;
  padding-left: 1rem;
}

.fm-desktop-card-title {
  font-size: 1.75rem;
  font-weight: 600;
}

</style>
