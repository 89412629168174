<template>
  <template v-if="deviceType === 'desktop'">
    <div class="container-fluid">
      <InfoPage
        v-bind:title="'Ups...'"
        v-bind:message1="'Oje! Skończył Ci się dostęp. Przedłuż dostęp i dalej ucz się angielskiego. Jeszcze tyle nowych słówek przed Tobą!'"
        v-bind:message2="'Jeśli właśnie opłaciłeś dostęp, a ten komunikat nadal się pojawia to odczekaj kilka minut i odśwież stronę. Jeśli komunikat nie zniknie w ciągu godziny - skontaktuj się z nami poprzez email: pomoc@funenglish.pl'"
        v-bind:image="'/images/icons/face-with-open-eyes-and-hand-over-mouth.svg'">
        <button class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-button" v-on:click="this.$router.push('/pricing')">
          Zakup<br> dostęp
        </button>
      </InfoPage>
    </div>
  </template>

  <template v-if="deviceType === 'mobile'">
  <div class="container vh-100 fm-background-main-color">
    <InfoPage
      v-bind:title="'Ups...'"
      v-bind:message1="'Oje! Skończyła Ci się subskrypcja. Przedłuż ją i dalej ucz się angielskiego. Jeszcze tyle nowych słówek przed Tobą!'"
      v-bind:message2="'Jeśli właśnie opłaciłeś dostęp, a ten komunikat nadal się pojawia to odczekaj kilka minut i odśwież stronę. Jeśli komunikat nie zniknie w ciągu godziny - skontaktuj się z nami poprzez email: pomoc@funenglish.pl'"
      v-bind:image="'/images/icons/face-with-open-eyes-and-hand-over-mouth.svg'">
    </InfoPage>
    <button class="btn btn-primary w-100 fm-login-button fm-button-main-background-color fm-button" v-on:click="this.$router.push('/pricing')">Zakup<br> dostęp</button>
  </div>
  </template>

</template>

<script>
import InfoPage from '../components/InfoPage';
export default {
  name: 'ExpiredSubscription',
  components: { InfoPage },
  data() {
    return {
      deviceType: 'desktop'
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }
    }
  }
};
</script>

<style scoped>
.fm-button {
  margin-top: 5rem;
  height: 9rem;
  font-size: 1.75rem;
}
</style>
